<template>
	<div class="add-cart" v-if="productLength > 0">
		<div class="fixed">
			<router-link class="btn" to="Cart">
				<div class="num">
					<ByIcon icon="cart-white" class="cart-icon" size="20" />
					{{ productLength }}
				</div>
				<div class="text">前往購物車</div>
				<div class="total">${{ $stepPrice(cartTotal) }}</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import ByIcon from '@/components/Icon'
export default {
	name: 'AddCart',
	components: { ByIcon },
	data() {
		return {}
	},
	computed: {
		cartTotal() {
			return this.$store.getters['user/cartTotal']
		},
		productLength() {
			return this.$store.getters['user/productLength']
		},
	},
}
</script>

<style lang="sass" scoped>
.add-cart
  padding-bottom: 56px
  z-index: 900
  .fixed
    position: fixed
    left: 0
    bottom: 0
    width: 100%
  .btn
    display: flex
    justify-content: space-between
    align-items: center
    background: #294B70
    color: #FFF
    text-decoration: none
    padding: 18px 18px
    padding-bottom: calc(constant(safe-area-inset-bottom) + 12px)
    padding-bottom: calc(env(safe-area-inset-bottom) + 12px)

  .num
    display: flex
    align-items: center
  .text
  .cart-icon
    position: relative
    top: -1px
    margin-right: 4px
  .total
</style>
