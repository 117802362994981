<template>
	<div class="center-page-minus" :class="{ '--kiosk': isKiosk }">
		<div v-if="!isKiosk && $store.state.store.openInfo">
			<div style="width: 100%; display: inline-block; margin-top: 5%">
				<div
					@click="openShopInfo"
					style="width: 95%; text-align: right; color: #1976d2"
				>
					門市資訊
				</div>
			</div>

			<div
				class="shopAnnouncement"
				v-if="
					this.$store.state.store.openInfo.shopAnnouncement &&
					this.$store.state.store.openInfo.shopAnnouncement.length > 0
				"
			>
				<div
					class="shopAnnouncementBackground"
					style="
						min-height: 100px;
						background: #f3f3f3;
						width: 90%;
						margin: 5%;
						padding: 5%;
						border-radius: 10px;
					"
				>
					<div
						class="shopAnnouncementContent"
						style="
							padding-top: 0;
							width: 100%;
							display: inline-block;
							word-wrap: break-word;
							line-height: 20px;
						"
						v-html="toHtml(this.$store.state.store.openInfo.shopAnnouncement)"
					></div>
				</div>
			</div>
			<div v-else style="margin-top: 5%"></div>

			<ByDialog
				:class="{ 'detail-dialog--kiosk': isKiosk }"
				width="90%"
				v-model="isShopInfo"
				title="門市資訊"
				@cancel="closeShopInfo"
				@confirm="closeShopInfo"
				:noCancelButton="true"
			>
				<div
					style="
						background: #f3f3f3;
						min-height: 50px;
						margin-top: 5px;
						display: flex;
						align-items: center;
					"
				>
					<div style="display: inline-block; margin-left: 2%; width: 30%">
						營業日
					</div>
					<div
						style="
							display: inline-block;
							width: 70%;
							margin-right: 2%;
							word-wrap: break-word;
						"
					>
						<div style="text-align: right" v-for="item in businessHours">
							{{ item }}
						</div>
					</div>
				</div>
				<div
					style="
						background: #f3f3f3;
						min-height: 50px;
						margin-top: 5px;
						display: flex;
						align-items: center;
					"
				>
					<div style="display: inline-block; margin-left: 2%; width: 30%">
						休息日
					</div>
					<div
						style="
							display: inline-block;
							width: 70%;
							margin-right: 2%;
							word-wrap: break-word;
						"
					>
						<div style="text-align: right">
							{{ restDays }}
						</div>
					</div>
				</div>
				<div
					v-if="
						this.$store.state.store.openInfo.shopPhone &&
						this.$store.state.store.openInfo.shopPhone.length > 0
					"
					style="
						background: #f3f3f3;
						min-height: 50px;
						margin-top: 5px;
						display: flex;
						align-items: center;
					"
				>
					<div style="display: inline-block; margin-left: 2%; width: 30%">
						連絡電話
					</div>
					<div
						style="
							display: inline-block;
							width: 70%;
							margin-right: 2%;
							word-wrap: break-word;
						"
					>
						<div style="text-align: right">
							{{ this.$store.state.store.openInfo.shopPhone }}
						</div>
					</div>
				</div>
				<div
					v-if="
						this.$store.state.store.openInfo.shopAddress &&
						this.$store.state.store.openInfo.shopAddress.length > 0
					"
					style="
						background: #f3f3f3;
						min-height: 50px;
						margin-top: 5px;
						display: flex;
						align-items: center;
					"
				>
					<div style="display: inline-block; margin-left: 2%; width: 30%">
						地址
					</div>
					<div
						style="
							display: inline-block;
							width: 70%;
							margin-right: 2%;
							word-wrap: break-word;
						"
					>
						<div style="text-align: right">
							{{ this.$store.state.store.openInfo.shopAddress }}
						</div>
					</div>
				</div>
			</ByDialog>
		</div>

		<div class="product-container">
			<div class="items" :style="{ marginRight: canBuy ? '350px' : 0 }">
				<ByPickerBar
					class="picker-bar"
					:class="{ '--kiosk': isKiosk }"
					:items="category"
					v-model="currentCategory"
					@onChange="onTabChange"
					ref="categoryTabs"
				/>
				<ByInput
					type="search"
					class="search"
					:class="{ '--kiosk': isKiosk }"
					placeholder="搜尋餐點"
					v-model="searchText"
					@input="searchTextInput"
					@search="search"
					ref="searchInputRef"
				/>
				<!--不是前台 且 店家不開放 -->
				<div
					class="not-open"
					v-if="
						$store.state.store.openInfo &&
						!firstLoad &&
						!isKiosk &&
						!isMobileOrder
					"
					:style="{ marginTop: isKiosk ? '16px' : 0 }"
				>
					很抱歉，目前店家未開啟手機點餐功能，若有點餐需求請直接與店家聯繫。
				</div>
				<!--不是前台 且 (非店家營業時間、店家不開放預約) 有開啟手機點餐-->
				<div
					class="not-open"
					v-if="
						$store.state.store.openInfo &&
						!firstLoad &&
						!isKiosk &&
						!(isOpen || isBooking) &&
						isMobileOrder
					"
					:style="{ marginTop: isKiosk ? '16px' : 0 }"
				>
					很抱歉，目前非營業時間，請於營業時間內進行點餐。
				</div>
				<div class="left category-title">
					{{ rows.length ? displayCategory : '查無符合餐點' }}
				</div>
				<div class="product-grid">
					<div
						:class="['product-item', item.remark ? '' : 'no-content']"
						v-for="item in rows"
						:key="item.itemId"
						@click="goDetail(item)"
					>
						<div class="product-info">
							<div class="product-name">
								{{ item.itemName }}
								<div class="product-status">
									<ByIcon icon="king" size="24" v-if="item.tag === 'HOT'" />
									<ByIcon icon="new" size="36" v-if="item.tag === 'NEW'" />
								</div>
							</div>
							<div class="product-content" v-if="item.description">
								{{ item.description }}
							</div>
							<div class="product-price">${{ $stepPrice(item.price) }}</div>
						</div>
						<div class="product-img" v-if="item.itemLogoUrl">
							<img
								:src="item.itemLogoUrl ? `${httpUrl}${item.itemLogoUrl}` : null"
								@error="handleItemPicLoadError(item)"
								alt="餐點圖片"
							/>
							<div v-if="!item.availableForSale" class="sold-out-text">
								售完
							</div>
						</div>

						<div class="product-plus" v-if="!item.itemLogoUrl && canBuy">
							<ByIcon v-if="item.availableForSale" icon="plus" />
							<div v-else class="sold-out-text">售完</div>
						</div>

						<!--售完-->
						<div class="sold-out-overlay" v-if="!item.availableForSale" />
					</div>
				</div>
				<ByFooter />
			</div>
			<div class="cart-panel" v-if="isKiosk && canBuy">
				<div class="cart-panel_title">我的購物車</div>
				<ByCartGrid
					class="cart-panel_grid"
					no-count
					:editFun="showDetailDialog"
					v-if="cartLength"
				/>
				<div class="cart-panel_welcome" v-else>歡迎開始點餐</div>
				<ByInput
					class="cart-panel_remark"
					placeholder="新增給店家的備註"
					type="textarea"
					v-model="$store.state.kioskOrderRemark"
				/>
				<div class="cart-panel_btns">
					<ByButton
						class="btn"
						stl="main2"
						@click="onShowClearCartConfirm"
						:disabled="!cartLength"
					>清空
					</ByButton>
					<ByButton
						class="btn send"
						stl="main1"
						@click="onToOrder"
						:disabled="!cartLength"
						:loading="isToOrderLoading"
					>
						<div class="btn_num" v-if="cartLength">[{{ productLength }}]</div>
						<div class="btn_text">結帳</div>
						<div class="btn_total" v-if="cartLength">${{ cartTotal }}</div>
					</ByButton>
				</div>
			</div>
		</div>
		<ByTopBtn :bottom="productLength ? '55px' : '15px'" v-if="!isKiosk" />
		<AddCart v-if="!isKiosk && canBuy" />
		<ByDialog
			:class="{ 'detail-dialog--kiosk': isKiosk }"
			width="465px"
			v-model="isDetailDialog"
			no-title
		>
			<Detail
				:detail-id="detailId"
				:isPropEdit="isEditDetail"
				ref="detailRef"
			/>
			<template slot="dialog-footer">
				<div class="kiosk-dialog-footer-wrap" v-if="canBuy">
					<ByButton stl="main1" @click="onEditCart" v-if="isEditDetail"
					>更新購物車
					</ByButton>
					<ByButton stl="main1" @click="onAddCart" v-else>加入購物車</ByButton>
				</div>
				<div v-else />
			</template>
		</ByDialog>
		<ByCompareCartConfirm ref="compareCartConfirmRef" />
		<ByClearCartConfirm v-model="isShowClearCartConfirm" />
	</div>
</template>

<script>
import AddCart from '@/views/buy/AddCart'
import Detail from '@/views/buy/Detail/Detail'
import ByDialog from '../../components/Dialog'

export default {
	components: { ByDialog, Detail, AddCart },
	data() {
		return {
			rows: [],
			category: [{ id: '0', name: '全部' }],
			currentCategory: '0',
			source: undefined,
			searchText: '',
			isAll: true,
			isDetailDialog: false,
			detailId: 0,
			isToOrderLoading: false,
			isShowClearCartConfirm: false,
			isEditDetail: false,
			firstLoad: true,
			isShopInfo: false,
			week: {
				1: '周一',
				2: '周二',
				3: '周三',
				4: '周四',
				5: '周五',
				6: '周六',
				7: '周日',
			},
		}
	},
	computed: {
		businessHours() {
			const result = []
			const businessHoursList =
				this.$store.state.store.openInfo.businessHoursList
			const restType = this.$store.state.store.openInfo.restType
			if (restType === 'FIXED_REST') {
				const restDays = this.$store.state.store.openInfo.restDays
				let start = null
				let end = null
				for (let i = 1; i < 8; i++) {
					if (restDays.indexOf(i) === -1) {
						if (start === null) {
							start = i
						}
						end = i
						if (restDays.indexOf(i + 1) !== -1 || i === 7) {
							businessHoursList.forEach(e => {
								if (start === end) {
									result.push(
										this.week[start] + ' ' + this.businessHoursText(e),
									)
								} else {
									result.push(
										this.week[start] +
										'至' +
										this.week[end] +
										' ' +
										this.businessHoursText(e),
									)
								}
							})
						}
					} else {
						if (start !== null) {
							start = null
							end = null
						}
					}
				}
			} else {
				businessHoursList.forEach(e => {
					result.push('每天 ' + this.businessHoursText(e))
				})
			}
			return result
		},
		restDays() {
			const restType = this.$store.state.store.openInfo.restType
			if (restType === 'FIXED_REST') {
				const restDays = this.$store.state.store.openInfo.restDays
				let temp = ''
				temp.endsWith()
				restDays.forEach(e => {
					temp = temp + this.week[e] + '、'
				})
				if (temp.endsWith('、')) {
					temp = temp.slice(0, temp.length - 1)
				}
				if (temp.length === 0) {
					return '無'
				} else {
					return temp
				}
			} else if (restType === 'UNFIXED_REST') {
				const restDates = this.$store.state.store.openInfo.restDates
				let temp = ''
				restDates.forEach(e => {
					temp = temp + e.split('-')[2] + '號、'
				})
				if (temp.endsWith('、')) {
					temp = temp.slice(0, temp.length - 1)
				}
				if (temp.length === 0) {
					return '無'
				} else {
					return '本月 ' + temp
				}
			} else {
				return '無'
			}
		},
		httpUrl() {
			return window.location.origin
		},
		displayCategory() {
			if (this.currentCategory) {
				return (
					this.category.find(item => item.id === this.currentCategory) &&
					this.category.find(item => item.id === this.currentCategory).name
				)
			}
			return '全部'
		},
		storeId() {
			return this.$store.state.user.storeId
		},
		cart() {
			return this.$store.getters['user/currCart']
		},
		cartLength() {
			const current = this.cart
			return current ? current.length : 0
		},
		productLength() {
			return this.$store.getters['user/productLength']
		},
		isKiosk() {
			return this.$store.getters.isKiosk
		},
		cartTotal() {
			return this.$store.getters['user/cartTotal']
		},
		canBuy() {
			return this.$store.getters.canBuy
		},
		isOpen() {
			return this.$store.getters.isOpen
		},
		isBooking() {
			return this.$store.getters.isBooking
		},
		isMobileOrder() {
			return this.$store.getters.isMobileOrder
		},
	},
	created() {
		if (this.$store.state.user.guestId && this.storeId) {
			this.currentCategory = sessionStorage.getItem('category')

			this.reloadMenu()
		}
	},
	watch: {
		'$store.state.user.guestId'(val, beforeVal) {
			if (val && this.storeId && this.firstLoad) {
				this.initStore()
			}
		},
		'$store.state.user.storeId'(val, beforeVal) {
			if (val) {
				this.firstLoad = true
			}
			if (val && this.$store.state.user.guestId) {
				this.initStore()
			}
		},
		async '$store.state.store.refreshMenu'(val, beforeVal) {
			if (val) {
				this.firstLoad = true
				await this.reloadMenu()
				this.$store.commit('store/SET_REFRESH_MENU', false)
			}
		},
	},
	methods: {
		async reloadMenu() {
			await this.initStore()
				.then(() => {
					document.documentElement.style.scrollBehavior = 'auto'
				})
				.then(() => {
					this.backToLastPagePosition()
				})
				.then(() => {
					document.documentElement.style.scrollBehavior = 'smooth'
				})
		},
		toHtml(content) {
			return content.replace(/\n/g, '<br>')
		},
		openShopInfo() {
			this.isShopInfo = true
		},
		closeShopInfo() {
			this.isShopInfo = false
		},
		businessHoursText(businessHours) {
			const [openHours, openMinutes] = businessHours.openTime.split(':')
			const [closeHours, closeMinutes] = businessHours.closeTime.split(':')
			return (
				openHours + ':' + openMinutes + '–' + closeHours + ':' + closeMinutes
			)
		},
		onEditCart() {
			const validate = this.$refs.detailRef.handleEdit() !== false
			if (validate) {
				this.isDetailDialog = false
			}
		},
		onAddCart() {
			const validate = this.$refs.detailRef.onAddCart() !== false
			if (validate) {
				this.isDetailDialog = false
			}
		},
		searchTextInput(v) {
		},
		search() {
			if (this.$refs.searchInputRef) {
				this.$refs.searchInputRef.blur()
			}
			this.initStore(true)
		},
		showDetailDialog(itemId) {
			this.detailId = itemId
			this.isEditDetail = true
			this.isDetailDialog = true
		},
		setCurrentTabPosition() {
			const pickerBar = this.$refs.categoryTabs.$el
			const activeTab = pickerBar.querySelector('.picker-tab.active')

			// 獲取active標籤距離picker-bar左側的距離
			const activeTabPosition = activeTab.offsetLeft

			// 獲取picker-bar容器的水平滾動位置
			const scrollPosition = pickerBar.scrollLeft
			sessionStorage.setItem('xPosition', String(scrollPosition))
		},
		goDetail(item) {
			if (!item.availableForSale) {
				return
			}
			const hasOptions = item.optionList && item.optionList.length > 0
			const hasUpgrades = item.upgradeList && item.upgradeList.length > 0
			const hasCombos = item.comboList && item.comboList.length > 0
			if ((hasOptions || hasUpgrades || hasCombos) && this.canBuy) {
				if (this.isKiosk) {
					this.detailId = item.itemId
					this.isEditDetail = false
					this.isDetailDialog = true
					return
				}

				this.setCurrentTabPosition()
				sessionStorage.setItem('category', this.currentCategory)
				sessionStorage.setItem('yPosition', window.scrollY)
				this.$router.push(`Detail/` + item.itemId)
			} else if (this.canBuy) {
				const store = {
					count: 1,
					itemId: item.itemId,
					itemName: item.itemName,
					itemOptionDetailList: [],
					itemPrice: item.price,
					itemRemark: item.remark,
					comboList: item.comboList || [],
				}
				this.$alert(`${item.itemName} 已加入購物車`, 'success')
				this.$store.commit('user/LOGIN_AMOUNT_CART', {
					cartItems: [store],
					isResetCart: false,
				})
			}
		},
		backToLastPagePosition() {
			this.onTabChange(this.currentCategory)

			const mealItemYPosition = sessionStorage.getItem('yPosition')
			const categoryTabXPosition = sessionStorage.getItem('xPosition')

			this.$nextTick(() => {
				this.$refs.categoryTabs.setStyle()
				let pickerBar = this.$refs.categoryTabs.$el
				pickerBar.scrollLeft = parseInt(categoryTabXPosition)
			})

			if (mealItemYPosition) {
				window.scrollTo({
					top: parseInt(mealItemYPosition),
					behavior: 'auto',
				})
			}
		},
		async initStore(isSearch) {
			if (this.firstLoad) {
				this.firstLoad = false
			}

			if (this.storeId === null || this.$store.state.user.guestId === null) {
				return
			}

			const result = (
				await this.$api.order.getMenu({
					storeId: this.storeId,
					keyword: this.searchText,
				})
			).data

			document.title = this.$store.getters.storeName || this.$route.meta.title

			this.isAll = this.searchText === ''

			this.source = result.menuList

			this.$store.commit('store/SET_MENUS', result.menuList)

			let category = [{ id: '0', name: '全部' }]

			result.menuList.forEach(menu => {
				if (menu.itemList.length) {
					category.push({
						id: menu.categoryId,
						name: menu.categoryName,
					})
				}
			})

			this.category = category

			const isInside = this.category.some(
				item => item.id === this.currentCategory,
			)
			this.currentCategory = isInside ? this.currentCategory : '0'
			sessionStorage.setItem('category', this.currentCategory)
			this.onTabChange(isInside ? this.currentCategory : null)

			isSearch &&
			this.$nextTick(() => {
				this.$refs.categoryTabs.setStyle()
			})
		},
		onTabChange(id) {
			if (id && id !== '0') {
				this.rows = this.source.find(item => item.categoryId === id).itemList
			} else {
				this.rows = this.source.reduce((row, item) => {
					return row.concat(item.itemList)
				}, [])
			}
		},
		handleItemPicLoadError(item) {
			this.$set(item, 'itemLogoUrl', null)
		},
		async onToOrder() {
			this.isToOrderLoading = true
			await this.$refs.compareCartConfirmRef
				.confirm()
				.finally(() => (this.isToOrderLoading = false))
		},
		onShowClearCartConfirm() {
			this.isShowClearCartConfirm = true
		},
	},
}
</script>

<style lang="sass" scoped>

.center-page-minus
	padding: 0

.category-title
	font-size: 20px
	font-weight: bolder
	margin-top: 10px

.picker-bar
	position: sticky
	top: 50px
	left: 0
	z-index: 5
	background: #fff

	&.--kiosk
		margin-right: auto
		width: 100%

.product-container
	display: flex
	align-items: flex-start

	.items
		flex: 1
		padding-bottom: 12px
		margin-right: 350px

		.category-title
			padding: 8px 17.5px 0

		.product
			&-grid
				width: 100%
				margin-bottom: 25px
				padding: 0 17.5px
				@media screen and (min-width: 700px)
					display: flex
					flex-wrap: wrap

			&-item
				display: flex
				border-bottom: 1px solid #D8D8D8
				padding: 15px 0
				position: relative

				&.no-content
					.product-info
						min-height: 50px

				.sold-out-overlay
					position: absolute
					z-index: 3
					top: 0
					right: 0
					bottom: 0
					left: 0
					background-color: rgba(255, 255, 255, 0.5) !important

				@media screen and (min-width: 700px)
					width: 50%
					&:nth-child(2n + 1)
						padding-right: 15px

			&-img
				width: 80px
				height: 80px
				position: relative
				border-radius: 15px
				overflow: hidden
				background: url(../../assets/load-img1.png)
				background-size: cover
				background-position: center center

				img
					width: 80px
					height: 80px
					position: absolute
					left: 0
					top: 0
					object-fit: cover
					display: flex
					align-items: center

				.sold-out-text
					position: absolute
					z-index: 3
					display: flex
					justify-content: center
					background-color: rgba(128, 128, 128)
					white-space: nowrap
					top: 50%
					left: 50%
					transform: translate(-50%, -50%)
					padding: 5px 20px
					color: white
					border-radius: 15px
					font-size: calc(8px + 0.625rem)

			&-info
				min-height: 80px
				flex: 1
				display: flex
				justify-content: space-between
				flex-direction: column
				flex-wrap: wrap
				margin: 0 12px

			&-status
				+fc
				margin-left: 5px
			//名稱
			&-name
				display: flex
				align-items: center
				font-size: 16px
				font-weight: bold
			//內容
			&-content
				min-height: 32px
				line-height: 18px
				margin-bottom: 4px
				color: #8E8E8E
				font-size: 14px

			&-price
				font-size: 15px

			&-plus
				display: flex
				justify-content: center
				align-items: center
				position: relative
				width: 80px
				height: 80px

				img
					width: 27.5px
					height: 27.5px

				.sold-out-text
					z-index: 3
					white-space: nowrap
					top: 0
					right: 0
					bottom: 0
					left: 0
					background-color: rgba(128, 128, 128)
					color: white
					border-radius: 15px
					padding: 5px 20px
					font-size: calc(8px + 0.625rem)

			&-empty
				min-height: 100px
				+fc
				color: #8E8E8E
				font-style: italic

	.cart-panel
		width: 350px
		height: calc(100% - 50px)
		position: fixed
		top: 50px
		right: 0
		z-index: 2
		background: #f9f9f9
		display: flex
		flex-direction: column

		&_title
			font-size: 20px
			font-weight: bolder
			padding: 12px 15px

		&_grid
			flex: 1
			overflow: auto
			-webkit-overflow-scrolling: touch

		&_welcome
			flex: 1
			font-size: 18px
			padding: 12px 15px
			color: rgba(#000, .5)

		&_remark
			display: block
			width: calc(100% - 30px)
			margin: 0 auto 12px

			&::v-deep
				textarea
					border-radius: 10px
					border: 1px solid #cecece
					padding: 10px
					height: 80px

		&_btns
			display: flex
			padding: 0 15px 12px

			.btn
				width: 80px

				&.send
					margin-left: 8px
					flex: 1
					align-items: center
					justify-content: center
					margin-right: 0
					padding: 0 12px

					.btn_text
						margin: 0 auto

.search
	width: calc(100% - 35px)
	display: block
	margin: 20px auto

	&.--kiosk
		width: 250px
		position: fixed
		right: 64px
		top: 5px
		margin: 0
		z-index: 1000

.not-open
	background: rgba(#294B70, .4)
	border-radius: 4px
	padding: 8px 12px
	line-height: 1.5
	width: calc(100% - 35px)
	margin: 0 auto
</style>

<style lang="sass">
.detail-dialog--kiosk
	.dialog-body
		padding: 0

	.kiosk-dialog-footer-wrap
		position: sticky
		bottom: 0
		background: #fff
		border-top: 1px solid #D8D8D8
		width: 100%
		height: 56px
		padding-top: 8px

		.btn
			width: calc(100% - 40px)
			margin: 0 auto
</style>
